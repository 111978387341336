<template>
  <div class="stars__wrapper" @mouseleave="setRating(defaultValue1)">
    <div :class="`stars__container_${size}`">
      <StarLeft
        v-if="size === 'default'"
        @mouseenter="setRating(0.5)"
        @click="onHandleClick(0.5)"
        :class="`stars__${starRating > 0 ? 'active' : 'passive'}`" />
      <StarRight
        v-if="size === 'default'"
        @mouseenter="setRating(1)"
        @click="onHandleClick(1)"
        :class="`stars__${starRating >= 1 ? 'active' : 'passive'}`" />
      <StarLeftSmall
        v-if="size === 'small'"
        @mouseenter="setRating(0.5)"
        @click="onHandleClick(0.5)"
        :class="`stars__${starRating > 0 ? 'active' : 'passive'}`" />
      <StarRightSmall
        v-if="size === 'small'"
        @mouseenter="setRating(1)"
        @click="onHandleClick(1)"
        :class="`stars__${starRating >= 1 ? 'active' : 'passive'}`" />
    </div>
    <div :class="`stars__container_${size}`">
      <StarLeft
        v-if="size === 'default'"
        @mouseenter="setRating(1.5)"
        @click="onHandleClick(1.5)"
        :class="`stars__${starRating > 1 ? 'active' : 'passive'}`" />
      <StarRight
        v-if="size === 'default'"
        @mouseenter="setRating(2)"
        @click="onHandleClick(2)"
        :class="`stars__${starRating >= 2 ? 'active' : 'passive'}`" />
      <StarLeftSmall
        v-if="size === 'small'"
        @mouseenter="setRating(1.5)"
        @click="onHandleClick(1.5)"
        :class="`stars__${starRating > 1 ? 'active' : 'passive'}`" />
      <StarRightSmall
        v-if="size === 'small'"
        @mouseenter="setRating(2)"
        @click="onHandleClick(2)"
        :class="`stars__${starRating >= 2 ? 'active' : 'passive'}`" />
    </div>
    <div :class="`stars__container_${size}`">
      <StarLeft
        v-if="size === 'default'"
        @mouseenter="setRating(2.5)"
        @click="onHandleClick(2.5)"
        :class="`stars__${starRating > 2 ? 'active' : 'passive'}`" />
      <StarRight
        v-if="size === 'default'"
        @mouseenter="setRating(3)"
        @click="onHandleClick(3)"
        :class="`stars__${starRating >= 3 ? 'active' : 'passive'}`" />
      <StarLeftSmall
        v-if="size === 'small'"
        @mouseenter="setRating(2.5)"
        @click="onHandleClick(2.5)"
        :class="`stars__${starRating > 2 ? 'active' : 'passive'}`" />
      <StarRightSmall
        v-if="size === 'small'"
        @mouseenter="setRating(3)"
        @click="onHandleClick(3)"
        :class="`stars__${starRating >= 3 ? 'active' : 'passive'}`" />
    </div>
    <div :class="`stars__container_${size}`">
      <StarLeft
        v-if="size === 'default'"
        @mouseenter="setRating(3.5)"
        @click="onHandleClick(3.5)"
        :class="`stars__${starRating > 3 ? 'active' : 'passive'}`" />
      <StarRight
        v-if="size === 'default'"
        @mouseenter="setRating(4)"
        @click="onHandleClick(4)"
        :class="`stars__${starRating >= 4 ? 'active' : 'passive'}`" />
      <StarLeftSmall
        v-if="size === 'small'"
        @mouseenter="setRating(3.5)"
        @click="onHandleClick(3.5)"
        :class="`stars__${starRating > 3 ? 'active' : 'passive'}`" />
      <StarRightSmall
        v-if="size === 'small'"
        @mouseenter="setRating(4)"
        @click="onHandleClick(4)"
        :class="`stars__${starRating >= 4 ? 'active' : 'passive'}`" />
    </div>
    <div :class="`stars__container_${size}`">
      <StarLeft
        v-if="size === 'default'"
        @mouseenter="setRating(4.5)"
        @click="onHandleClick(4.5)"
        :class="`stars__${starRating > 4 ? 'active' : 'passive'}`" />
      <StarRight
        v-if="size === 'default'"
        @mouseenter="setRating(5)"
        @click="onHandleClick(5)"
        :class="`stars__${starRating === 5 ? 'active' : 'passive'}`" />
      <StarLeftSmall
        v-if="size === 'small'"
        @mouseenter="setRating(4.5)"
        @click="onHandleClick(4.5)"
        :class="`stars__${starRating > 4 ? 'active' : 'passive'}`" />
      <StarRightSmall
        v-if="size === 'small'"
        @mouseenter="setRating(5)"
        @click="onHandleClick(5)"
        :class="`stars__${starRating === 5 ? 'active' : 'passive'}`" />
    </div>
  </div>
</template>

<script>
const StarLeft = () => import('@/assets/pictures/stars/StarLeft.svg');
const StarRight = () => import('@/assets/pictures/stars/StarRight.svg');
const StarLeftSmall = () => import('@/assets/pictures/stars/StarLeftSmall.svg');
const StarRightSmall = () => import('@/assets/pictures/stars/StarRightSmall.svg');

export default {
  name: 'Stars',
  data() {
    return {
      starRating: null,
      defaultValue1: null,
    };
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    rating: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'default', // small
    },
  },
  watch: {
    rating: function (newVal) {
      this.defaultValue1 = newVal;
    },
  },
  methods: {
    setRating(value) {
      if (this.editable) {
        this.starRating = value;
      }
    },
    onHandleClick(value) {
      if (this.editable) {
        this.starRating = value;
        this.defaultValue1 = value;
        this.$emit('click', value);
      }
    },
  },
  mounted() {
    this.starRating = this.rating;
    if (this.rating) {
      this.defaultValue1 = this.rating;
    } else {
      this.defaultValue1 = 0;
    }
  },
  components: {
    StarLeft,
    StarRight,
    StarLeftSmall,
    StarRightSmall,
  },
};
</script>
